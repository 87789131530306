.small-circle-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .small-circle {
    width: 100px;
    height: 100px;
    background-color: rgb(168, 172, 168);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    font-size: 16px;
    color: black;
  }
  
  .small-circle:nth-child(5) {
    top: -43px; /* Ajustez cette valeur pour positionner le cercle où vous le souhaitez */
    left: 50%;
    transform: translateX(-50%);
  }

  
  .small-circle:nth-child(2) {
    top: 50%;
    right: -63px;
    transform: translateY(-50%);
  }
  
  .small-circle:nth-child(3) {
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .small-circle:nth-child(4) {
    top: 50%;
    left: -64px;
    transform: translateY(-50%);
  }
  