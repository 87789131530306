.footer {
    height: max-content;
    width: 100%;
    background-color: #1539CF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;    
}

.left_element {
    color: white;
    margin: 10px 0;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
}

.right_element {
    margin: 10px 0;
    text-align: center;
}

.button_element {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.button_element button {
    border-radius: 22px;
    background: #056EF6;
    border: none;
    padding: 15px 25px;
    margin-bottom: 10px;
}

.link_btn {
    color: white;
}

.link_left,
.link_right {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin-right: 13px;
}

/* Styles pour la responsivité tablette */
@media only screen and (min-width: 960px) {
    .footer {
        flex-direction: row;
        height: max-content;
        width: 100%;
    }

    .left_element {
        margin: 0;
        text-align: left;
    }

    .right_element {
        text-align: right;
    }

    .button_element {
        margin-top: 0;
    }
}

/* Styles pour la responsivité telephone*/
@media only screen and (min-width: 640px) {
    .footer {
        flex-direction: row;
        height: max-content;
        width: 100%;
    }


    .left_element {
        margin: 0;
        text-align: left;
    }

    .right_element {
        text-align: right;
    }

    .button_element {
        margin-top: 0;
    }
}