.title_services {
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 28px;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    display: flex;
    background-color: #056df664;
    border-radius: 19px;
}

.part_text {
    width: 50%;
}

.part_text button {
    width: 100px;
    height: 40px;
    background: #056EF6;
    color: white;
    border: none;
    border-radius: 22px;
    font-size: 16px;
}

.text_color {
    text-decoration: none;
    color: white;
    text-align: start;
}

.paragraphe_title {
    font-size: 25px;
    text-align: justify;
}

.paragraphe_title h2 {
    text-align: start;
}

.part_img {
    width: 50%;
}

.part_img img {
    height: 50vh;
}

.style_btn {
    background: #056EF6;
    border: none;
    padding: 15px 25px 15px 25px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
}

.style_btn .link_btn {
    font-size: 15px;
}

.content_card {
    padding-bottom: 29px;
}

.paragraphe_content {
    font-size: 25px;
}

/* Ajustements pour les tablettes */
@media (max-width: 960px) {
    .title_services {
        flex-direction: column;
        text-align: center;
        padding: 15px;
    }

    .part_text,
    .part_img {
        width: 100%;
    }

    .part_img img {
        height: auto;
        max-width: 100%;
    }

    .style_btn {
        margin: 0 auto;
    }

    .style_btn .link_btn {
        text-align: center;
    }
}

/* Ajustements pour les téléphones */
@media (max-width: 640px) {
    .title_services {
        margin: 15px;
    }

    .paragraphe_title {
        font-size: 18px;
    }

    .part_text,
    .part_img {
        width: 100%;
    }

    .part_img img {
        height: auto;
        max-width: 100%;
    }

    .style_btn {
        margin: 0 auto;
    }

    .style_btn .link_btn {
        text-align: center;
    }
}