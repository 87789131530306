.offre_element{
    margin-left: 35px;
    margin-right: 69px;
    padding-top: 65px;
   
}

.content_offre {
    height: 520px;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.container_offre section {
    scroll-snap-align: start;
}