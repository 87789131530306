.content_card{  
    margin-left: 35px;
    margin-right: 35px;
    padding-top: 45px;
}


.cards{
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container{
    margin-left: 35px;
    margin-right: 35px;
    padding-top: 45px;
}

.flex_content{
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* Ajustements pour les tablettes */
@media (max-width: 960px) {
    .content_card {
        margin: 15px; 
        padding-top: 15px; 
    }

    .cards {
        height: auto; 
        flex-direction: column; 
        align-items: flex-start; 
    }

    .container {
        margin: 15px; 
        padding-top: 15px; 
        margin-top: 10px;
    }

    .flex_content {
        height: auto; 
        flex-direction: column; 
        align-items: flex-start; 
    }
}

/* Ajustements pour les téléphones */
@media (max-width: 640px) {
    .content_card {
        margin: 15px; 
        padding-top: 15px; 
    }

    .cards {
        height: auto; 
        flex-direction: column; 
        align-items: flex-start; 
    }

    .container {
        margin: 15px; 
        padding-top: 15px; 
    }

    .flex_content {
        height: auto; 
        flex-direction: column; 
        align-items: flex-start; 
    }


}

