.post_content{
    background-color: #b9bcc9;
    border-radius: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 10px;
}

.post_container{
    display: flex;
    justify-content: space-around;
}