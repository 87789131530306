.element_content {
    display: flex;
    width: 50%;
    height: 49vh;
    justify-content: center;
    flex-direction: row;
    box-shadow: -6px 6px 12px #c1c1c1,
        6px -6px 12px #ebebeb;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 22px;
    margin-top: 10px;

}

.content_container .text_align {
    text-align: start;
    padding: none;
    margin: none;
}

/* Ajustements pour les tablette */
@media (max-width: 960px) {
    .element_content {
        width: 78%;
    }
}


/* Ajustements pour les telephone */
@media (max-width: 640px) {
    .element_content {
        width: 85%;
    }

    .element_content p {
        font-size: 18px;
    }
}