/* Styles pour la barre de navigation */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1539CF;
  padding: 10px;
}

.nav_left,
.nav_right {
  display: flex;
}

/* Styles pour les liens de la barre de navigation */
.link_accueil,
.link_services,
.link_entreprise,
.link_carriere,
.link_contact,
.link_falinkedin {
  margin-right: 20px;
  text-decoration: none;
  color: rgb(193, 193, 193);
  font-size: 16px;
  font-weight: bold;
}

.link_accueil:hover,
.link_services:hover,
.link_entreprise:hover,
.link_carriere:hover,
.link_contact:hover,
.link_falinkedin:hover {
  color: yellow;
}

/* Styles pour le logo */
.element_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100px;
  height: auto;
}

/* Styles pour le menu burger sur les dispositifs mobiles et tablettes */
.burger-icon {
  display: none;
  cursor: pointer;
}

.burger-icon div {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

.nav-links {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  /* ajustez selon votre mise en page */
  right: 10px;
  background-color: #1539CF;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: none;
}

.nav-links a {
  text-decoration: none;
  color: rgb(193, 193, 193);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.nav-links.open {
  display: flex;
}

/* Styles pour les liens du menu burger */
@media only screen and (max-width: 960px) {

  .nav_left,
  .nav_right {
    display: none;
  }

  .burger-icon {
    display: flex;
    flex-direction: column;
  }

  .nav-links {
    top: 70px;
    right: 0;
    left: 0;
    width: 100%;
  }
}

/* Styles pour les liens du menu burger */
@media only screen and (max-width: 640px) {

  .nav_left,
  .nav_right {
    display: none;
  }

  .burger-icon {
    display: flex;
    flex-direction: column;
  }

  .nav-links {
    top: 23px;
    right: 0;
    left: 0;
    width: 95%;
    z-index: 1;
  }
}