.main-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 377px;
    height: 377px;
    background-color: #fafbfcc8;;
    border-radius: 50%;
    position: relative;
    margin: 50px auto;
}

.description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
    font-size: 24px;
}

#tsparticles{
    position: absolute !important;
   
}

.content_circle{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}


/* Ajustements pour les tablettes */
@media (max-width: 960px) {
   
}


/* Ajustements pour les telephone*/
@media (max-width: 640px) {
    .content_circle{
        display: none;
    }
}