.content_contact {
    margin-left: 35px;
    margin-right: 35px;
    padding-top: 65px;
    padding-bottom: 35px;
}

.container_contact {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.contact_left {
    width: 60%;
    margin-right: 50px;
}

.contact {
    display: flex;
    flex-direction: column;
}

.contact_right {
    width: 40%;
    height: 24vh;
    border-radius: 13px;
    padding-left: 12px;
    background-color: #056df671;
}

.contact input {
    margin-top: 18px;
    height: 40px;
}

.contact textarea {
    margin-top: 18px;
}

.btn_contact {
    width: 100px;
    height: 40px;
    background: #056EF6;
    color: white;
    border: none;
    margin-top: 19px;
    border-radius: 20px;
    font-size: 15px;
}

/* Ajustements pour les tablettes */
@media (max-width: 960px) {

    .content_contact {
        margin: 15px;
        padding-top: 25px;
        padding-bottom: 15px;
    }

    .container_contact {
        flex-direction: column;
        align-items: flex-start;
    }

    .contact_left {
        width: 100%;
        margin-right: 0;
    }

    .contact_right {
        width: 100%;
        height: auto;
    }
}

/* Ajustements pour les téléphones */
@media (max-width: 640px) {

    .content_contact {
        margin: 15px;
        padding-top: 25px;
        padding-bottom: 15px;
    }

    .container_contact {
        flex-direction: column;
        align-items: flex-start;
    }

    .contact_left {
        width: 100%;
        margin-right: 0;
    }

    .contact_right {
        width: 100%;
        height: auto;
        margin-top: 18px;
    }

    .contact input,
    .contact textarea {
        width: 100%;
    }
}