.head_content {
    height: 74vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.color_content {
    background-color: rgba(31, 31, 31, 0.308);
    height: 74vh;
    width: 100%;
}

.head_container {
    margin-left: 35px;
    margin-right: 600px;
    background-color: rgba(255, 255, 255, 0.278);
    padding: 10px 20px;
    border-radius: 15px;
    top: 146px;
    width: 69%;
    position: absolute;
}

.head_container p {
    font-size: 25px;
}

.btn_head {
    background: #056EF6;
    border: none;
    padding: 15px 0px 15px 0px;
    margin-bottom: 10px;
    width: 20%;
    text-align: center;
    border-radius: 26px;
    color: white;
    font-size: 15px;
}

.link_head {
    border: none;
    text-decoration: none;
    color: aliceblue;
    font-size: 16px;
}

/* Ajustements pour les tablettes */
@media (max-width: 960px) {

    .head_container {
        top: 146px;
        width: 69%;
        height: max-content;
    }

}


/* Ajustements pour les telephone */
@media (max-width: 640px) {

    .head_container {
        width: 82%;
        bottom: 100px;
        top: 234px;
        margin-left: 12px;
        height: max-content;
    }

    .head_container p {
        font-size: 18px;
    }

    .head_container h1 {
        font-size: 27px;
    }

    .btn_head {
        display: none;
    }

    .contact_right {
        margin-top: 10px;
    }
}