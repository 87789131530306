.card_content{
    width: 100%;
    height: 39vh;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    perspective: 1000px;
}

h2{
    text-align: center;
}

.card_container{
    display: flex;
    align-items: center;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.card_content:hover .card_container {
    transform: rotateY(180deg);
}

.card_front,
.card_back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 300px;
    background-size: cover;
}

.card_front {
    background-color: rgba(255, 255, 255, 0.278);
}

.card_front h2 {
    background-color:  rgba(255, 255, 255, 0.776);
    padding-left: 16px;
    border-radius: 10px;
    padding-right: 16px;
}

.card_back {
    transform: rotateY(180deg);
    font-size: 20px;
    text-align: start;
    color: rgb(17, 16, 16);
    font-size: 22px;
    background-size: cover;
}

.card_back p{
    background-color: rgba(255, 255, 255, 0.776);
    padding-left: 16px;
    border-radius: 10px;
    padding-right: 16px;
    padding-top: 16px;
}

/* Ajustements pour les tablette */
@media (max-width: 960px) {

    .card_content{
        width: 80%;
        margin-top: 14px;
    }

    .card_back p {
        font-size: 24px;
    }
}

/* Ajustements pour les telephone */
@media (max-width: 640px) {
    .card_content{
        width: 80%;
        margin-top: 20px;
    }

    .card_back p {
        font-size: 14px;
    }
}