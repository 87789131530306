.main{
    height: 68vh;
}

.content_box{
    margin-left: 35px;
    margin-right: 35px;
    padding-top: 15px;
    margin-bottom: 40px;
}

.container_box{
    display: flex;
    justify-content: space-between;

}

.box_left{
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.box_left img{
    border-radius: 10px;
}

.box_right{
    width: 50%;
    display: flex;
    margin-left: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.box_right img{
    border-radius: 10px;
}

.box_bscheck{
    font-size: 20px;
    margin-right: 10px;
}

.box_left button{
    background: #056EF6;
    border: none;
    padding: 15px 25px 15px 25px;
    margin-bottom: 10px;
    border-radius: 22px;
}

.box_right button{
    background: #056EF6;
    border: none;
    padding: 15px 25px 15px 25px;
    margin-bottom: 10px;
    border-radius: 22px;
}

.post_box {
    margin-right: 10px;
    background-color: #056df664;
    padding: 8px;
    border-radius: 13px;
}

.post_container {
    display: flex;
    width: 100%;
}

.img_post{
    width: 20%;
    margin-right: 10px;
}

.text_post{
    width: 80%;
}

.txt_container{
    width: 80%;
}

.link_btn{
    text-decoration: none;
}



/* Ajustements pour les tablettes */
@media (max-width: 960px) {
    .main {
        height: auto; 
    }

    .content_box {
        margin: 15px;
        padding: 10px; 
    }

    .container_box {
        flex-direction: column;
    }

    .box_left,
    .box_right {
        width: 100%; 
        margin-left: 0; 
    }
}

/* Ajustements pour les téléphones */
@media (max-width: 640px) {
    .main {
        height: auto; 
    }

    .content_box {
        margin: 15px;
        padding: 10px; 
    }

    .container_box {
        flex-direction: column; 
    }

    .box_left,
    .box_right {
        width: 100%; 
        margin-left: 0; 
        margin-top: 20px;
    }
}
