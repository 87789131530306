.offre_content {
    margin-left: 35px;
    padding-top: 95px;
}

.candidature_content {
    margin-left: 35px;
    padding-top: 50px;
    padding-bottom: 40px;
}

.section_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-right: 2px;

}

.offre_container button {
    width: 100px;
    height: 40px;
    background: #056EF6;
    color: white;
    border: none;
    border-radius: 22px;
    font-size: 16px
}

.section_content .section_block {
    width: 50%;
    margin-bottom: 10px;

}

.block_content {
    margin-right: 10px;
    background-color: #056df664;
    padding: 8px;
    border-radius: 13px;
}

.block_container {
    display: flex;
    width: 100%;
}

.img_container {
    width: 20%;
    margin-right: 10px;
}

.txt_container {
    width: 80%;
}

.candidature_container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.candidature button {
    width: 100px;
    height: 40px;
    background: #056EF6;
    color: white;
    border: none;
    border-radius: 22px;
    font-size: 16px;
    margin-top: 15px;
}

.candidature_left {
    display: flex;
    flex-direction: column;
    width: 35%;
}

.candidature_left input {
    margin-top: 18px;
    width: 317px;
    height: 40px;
}

.candidature_right {
    display: flex;
    flex-direction: column;
    width: 35%;
}

.input_poste {
    width: 400px;
    height: 40px;
}

.candidature_right input {
    margin-top: 18px;
    width: 317px;
    height: 40px;
}

.title_carriere {
    margin-left: 35px;
    margin-right: 35px;
    padding-top: 45px;
    text-align: center;
    margin: 0 auto;
    max-width: 1025px;
}

.title_tbpoint {
    font-size: 23px;
    margin-right: 10px;
}

.img {
    width: 100%;
    margin-top: 18px;
    border-radius: 50px;
}

/* Ajustements pour les tablettes */
@media (max-width: 960px) {
    .section_content {
        flex-direction: column;
    }

    .section_block {
        width: 100%;
    }

    .section_content .section_block {
        width: 50%;
        margin-bottom: 10px;

    }

    /* Ajustements pour les téléphones */
    @media (max-width: 640px) {
        .candidature_container {
            flex-direction: column;
            width: 87%;
        }

        .input_poste{
            width: 87%;
        }

        .section_content .section_block {
            width: 100%;
            margin-bottom: 10px;

        }

        .candidature_left,
        .candidature_right {
            width: 100%;
        }

        
    }
}